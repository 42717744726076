// 
// _demos.scss
// 

// Demo Only
.button-items {
    margin-left: -8px;
    margin-bottom: -12px;
    
    .btn {
        margin-bottom: 12px;
        margin-left: 8px;
    }
}

// Lightbox 

.mfp-popup-form {
    max-width: 1140px;
}

.social-icon{
  .social-list-item{
    position: relative;
    color: $gray-500;
    .badge{
      position: absolute;
      top: -10px;
      right: -4px;  
    }
  }
}

// Modals

.bs-example-modal {
    position: relative;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
    z-index: 1;
    display: block;
  }


// Icon demo ( Demo only )
.icon-demo-content {
    text-align: center;
    color: $gray-500;
  
    i, .uim-svg{
      display: block;
      font-size: 24px;
      color: $gray-600;
      width: 48px;
      height: 48px;
      line-height: 46px;
      margin: 0px auto;
      margin-bottom: 16px;
      border-radius: 4px;
      border: 1px solid var(--#{$prefix}border-color);
      transition: all 0.4s;
    }


  
    .col-lg-4 {
      margin-top: 24px;
  
      &:hover {
        i {
          background-color: $primary;
          color: $white;
        }

        .uim-svg{
          background-color: $primary;
          fill: $white !important;
        }
      }
    }
}

.uim-svg{
  fill: $primary !important;
  svg{
    width: 24px;
    height: 24px;
    vertical-align: inherit;
  }
}

@each $color,
$value in $theme-colors {
    .uim-icon-#{$color} {
      .uim-svg{
        fill: $value !important;
      }
    }
}

.icons-sm {
  svg{
    height: 18px;
    width: 18px;
  }
}

.icons-md {
  svg{
    height: 32px;
    width: 32px;
  }
}

.icons-lg {
    svg{
    height: 40px;
    width: 40px;
  }
}

.icons-xl {
    svg{
    height: 48px;
    width: 48px;
  }
}

// Grid

.grid-structure {
    .grid-container {
        background-color: $gray-100;
        margin-top: 10px;
        font-size: .8rem;
        font-weight: $font-weight-medium;
        padding: 10px 20px;
    }
}