//
// alertify.scss
//


.alertify {
    .ajs-dialog {
        box-shadow: $box-shadow;
    }

    .ajs-footer {
        .ajs-buttons {
            .ajs-button {
                &.ajs-ok {
                    color: $primary;
                }
            }
        }
    }
}

.alertify-notifier {
    .ajs-message {
        background-color: $primary;
        border-color: $primary;
        color: $white;
        text-shadow: none !important;

        &.ajs-success {
            background-color: $success;
            border-color: $success;
        }

        &.ajs-error {
            background-color: $danger;
            border-color: $danger;
        }

        &.ajs-warning {
            background-color: $warning;
            border-color: $warning;
        }
    }
}

.ajs-modal {
    .ajs-dialog {
        background-color: var(--#{$prefix}secondary-bg);

        .ajs-header {
            background-color: var(--#{$prefix}secondary-bg);
            color: var(--#{$prefix}secondary-color);
            border-bottom-color: var(--#{$prefix}border-color);
        }

        .ajs-body {
            .ajs-content {
                color: var(--#{$prefix}secondary-color);

                .ajs-input {
                    border-color: var(--#{$prefix}border-color);
                }
            }
        }

        .ajs-footer {
            background-color: var(--#{$prefix}secondary-bg);
            border-top-color: var(--#{$prefix}border-color);

            .ajs-button {
                &.ajs-cancel {
                    color: var(--#{$prefix}secondary-color);
                }
            }
        }
    }
}