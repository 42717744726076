// 
// _footer.scss
// 

.footer {
    bottom: 0;
    padding: 20px calc(#{$grid-gutter-width} / 2);
    position: absolute;
    right: 0;
    color: var(--#{$prefix}footer-color);
    left: 250px;
    height: var(--#{$prefix}footer-height);
    box-shadow: $box-shadow;
    background-color: var(--#{$prefix}footer-bg);
}
  
@media (max-width: 992px) {
    .footer {
        left: 0;
    }
}

// Enlarge menu
.vertical-collpsed {
    .footer {
        left: var(--#{$prefix}sidebar-collapsed-width);
    }
}

body[data-layout="horizontal"] {
    .footer {
        left: 0 !important;
    }  
}